import React, { useRef, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

export default function RotatingConicalGradient({
    color,
    length,
    duration,
    repeatType,
    animate,
    loop,
    distance,
    angle,
    eased,
}) {
    const conicGradientRef = useRef(null)
    const [aspectRatio, setAspectRatio] = useState(1)
    useEffect(() => {
        if (conicGradientRef.current) {
            const { width, height } =
                conicGradientRef.current.getBoundingClientRect()

            setAspectRatio(width / height)
        }
    }, [conicGradientRef.current])

    return (
        <motion.div
            ref={conicGradientRef}
            style={{
                display: "grid",
                alignItems: "center",
                color: "white",
                justifyItems: "center",
                width: "100%",
                height: "100%",
                background: `conic-gradient(from -55deg at ${
                    distance / aspectRatio
                }% ${distance}% , transparent ${
                    angle - (10 + length)
                }deg, ${color}  ${angle}deg, transparent ${angle + length}deg)`,
            }}
            initial="initial"
            animate={{
                background: [
                    `conic-gradient(from -55deg at ${
                        distance / aspectRatio
                    }% ${distance}% , transparent ${
                        angle - (2 + length)
                    }deg, ${color}  ${angle}deg, transparent ${
                        angle + length
                    }deg)`,
                    `conic-gradient(from 35deg at ${
                        100 - distance / aspectRatio
                    }% ${distance}% , transparent ${
                        angle - (2 + length)
                    }deg, ${color}  ${angle}deg, transparent ${
                        angle + length
                    }deg)`,
                    `conic-gradient(from 125deg at ${
                        100 - distance / aspectRatio
                    }% ${100 - distance}% , transparent ${
                        angle - (2 + length)
                    }deg, ${color}  ${angle}deg, transparent ${
                        angle + length
                    }deg)`,
                    `conic-gradient(from 215deg at ${distance / aspectRatio}% ${
                        100 - distance
                    }% , transparent ${
                        angle - (2 + length)
                    }deg, ${color}  ${angle}deg, transparent ${
                        angle + length
                    }deg)`,
                    `conic-gradient(from 310deg at ${
                        distance / aspectRatio
                    }% ${distance}% , transparent ${
                        angle - (2 + length)
                    }deg, ${color}  ${angle}deg, transparent ${
                        angle + length
                    }deg)`,
                ],
            }}
            transition={{
                duration,
                repeat: loop ? Infinity : 1,
                repeatType,
                ease: "linear",
                [eased && "times"]:
                    aspectRatio > 1
                        ? [
                              0,
                              0.25 + 0.25 / aspectRatio,
                              0.5,
                              0.75 + 0.25 / aspectRatio,
                              1,
                          ]
                        : [
                              0,
                              aspectRatio * 0.25,
                              0.5,
                              0.5 + aspectRatio * 0.25,
                              1,
                          ],
            }}
        />
    )
}

addPropertyControls(RotatingConicalGradient, {
    color: {
        type: ControlType.Color,
        title: "Color",
        defaultValue: "#ff5e5e",
    },
    angle: {
        type: ControlType.Number,
        title: "Angle",
        defaultValue: 315,
        min: 0,
        max: 360,
        step: 1,
    },
    length: {
        type: ControlType.Number,
        title: "Length",
        defaultValue: 10,
        min: 1,
        max: 100,
        step: 1,
    },
    distance: {
        type: ControlType.Number,
        title: "Distance",
        defaultValue: 5,
        min: 1,
        max: 100,
        step: 1,
    },
    duration: {
        type: ControlType.Number,
        title: "Duration",
        defaultValue: 4,
        min: 1,
        max: 20,
        step: 1,
    },
    animate: {
        type: ControlType.Boolean,
        title: "Animate Rotation",
        defaultValue: true,
    },
    eased: {
        type: ControlType.Boolean,
        title: "Ease",
        defaultValue: true,
    },
    loop: {
        type: ControlType.Boolean,
        title: "Loop",
        defaultValue: true,
    },
    repeatType: {
        type: ControlType.Enum,
        title: "Replay Type",
        defaultValue: "loop",
        options: ["loop", "reverse", "mirror"],
        optionTitles: ["Loop", "Reverse", "Mirror"],
        hidden(props) {
            return !props.loop
        },
    },
})
